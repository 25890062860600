<template>
  <div class="commercial-step">
    <div class="row">
      <div class="col-md-4 mob-stepper">
        <div
          class="package-order-info"
          :class="`package-order-info${$route.params.id}`"
        >
          <ul class="order-status">
            <li :class="[index == 0 || index > 0 ? `active` : ``]">
              <span>1</span>
              <div>
                {{
                  this.$i18n.locale == `ar`
                    ? `اضف معلوماتك الشخصية`
                    : `add your personal information`
                }}
              </div>
            </li>
            <li class="gray" :class="[index > 0 ? `active` : ``]">
              <span>2</span>
              <div>
                {{
                  this.$i18n.locale == `ar`
                    ? ` حدد موقع العمل الخاص بك`
                    : `Determine your work
                                location`
                }}
              </div>
            </li>
            <li class="gray" :class="[index > 1 ? `active` : ``]">
              <span>3</span>
              <div>
                {{
                  this.$i18n.locale == `ar`
                    ? `الإجابة على الأسئلة`
                    : `answer the questions`
                }}
              </div>
            </li>
            <li class="gray" :class="[index > 2 ? `active` : ``]">
              <span>4</span>
              <div>
                {{
                  this.$i18n.locale == `ar`
                    ? `أتمم طلبك`
                    : `Complete your order`
                }}
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-8 pr-4 pl-4">
        <Form
          class="commercial-form"
          @submit="onSubmit"
          :validation-schema="currentSchema"
        >
          <swiper
            :slides-per-view="1"
            class="swiper"
            @swiper="onSwiper"
            @slideChange="onSlideChange"
            :autoHeight="true"
            :scrollbar="{ draggable: true }"
            :space-between="10"
            :allowTouchMove="false"
            :navigation="{
              enabled: true,
              nextEl: `.myNext`,
              prevEl: `.myPrev`,
            }"
          >
            <swiper-slide class="mob">
              <div class="gray-title">
                <div class="row align-items-center">
                  <div class="col-sm-2 p-0 col-4">
                    <img
                      class="w-100"
                      src="@/assets/img/commercial-franchise/slider1.png"
                    />
                  </div>
                  <div class="col-sm-10 col-8">
                    <h4>
                      {{
                        this.$i18n.locale == `ar`
                          ? `اضف معلوماتك الشخصية`
                          : `add your personal
                                            information`
                      }}
                    </h4>
                  </div>
                </div>
              </div>
              <div class="row itemm pt-4 pb-4">
                <div class="col-md-6 mt-3">
                  <Field
                    :placeholder="$t(`Full_name`)"
                    name="name"
                    type="text"
                    v-model="name"
                  />
                  <ErrorMessage name="name" class="commercial-form-error" />
                </div>
                <div class="col-md-6 mt-3">
                  <input
                    id="phone"
                    type="tel"
                    name="mobile_number"
                    v-model="phone"
                  />
                  <span id="output" class="commercial-form-error mt-1 mb-1">
                  </span>
                </div>
                <div class="col-md-6 mt-3">
                  <Field
                    :placeholder="$t(`Email`)"
                    name="email"
                    type="text"
                    v-model="email"
                  />
                  <ErrorMessage name="email" class="commercial-form-error" />
                </div>

                <div class="col-md-6 mt-3">
                  <Field
                    :placeholder="$t(`qualification`)"
                    type="text"
                    v-model="educational_qualification"
                    name="educational_qualification"
                  />
                  <ErrorMessage
                    name="educational_qualification"
                    class="commercial-form-error"
                  />
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="gray-title">
                <div class="row align-items-center">
                  <div class="col-sm-2 col-4">
                    <img src="@/assets/img/commercial-franchise/slider2.png" />
                  </div>
                  <div class="col-sm-10 col-8">
                    <h4>
                      {{
                        this.$i18n.locale == `ar`
                          ? ` حدد موقع العمل الخاص بك`
                          : `Determine your
                                            work location`
                      }}
                    </h4>
                  </div>
                </div>
              </div>

              <div class="row mt-4">
                <h5>
                  {{
                    this.$i18n.locale == `ar`
                      ? `او يمكنك التحديد على الخريطة`
                      : `Or you can select
                                    on the map`
                  }}
                </h5>

                <p>city: {{ this.city }}</p>
                <Field
                  v-model="city"
                  id="city"
                  type="hidden"
                  name="city"
                  value=""
                />
                <ErrorMessage name="city" class="commercial-form-error" />

                <div class="col-md-12">
                  <GoogleMap @my-event="handleArrayFromChild" />
                  <!-- <div class="d-flex" style="opacity: 0; height: 0;"> -->
                  <!-- <Field v-model="latitude" id="latitude" type="text" name="latitude" />
                                <ErrorMessage name="latitude" class="commercial-form-error" />

                                <Field v-model="longitude" id="longitude" type="text" name="longitude" />
                                <ErrorMessage name="longitude" class="commercial-form-error" /> -->

                  <!-- </div> -->
                </div>
              </div>
            </swiper-slide>
            <swiper-slide class="mob-qes" style="min-height: 1050px">
              <div class="gray-title">
                <div class="row align-items-center">
                  <div class="col-sm-2 col-4">
                    <img src="@/assets/img/commercial-franchise/slider3.png" />
                  </div>
                  <div class="col-sm-10 col-8">
                    <h4>
                      {{
                        this.$i18n.locale == `ar`
                          ? `الإجابة على الأسئلة`
                          : `answer the
                                            questions`
                      }}
                    </h4>
                  </div>
                </div>
              </div>
              <div class="row itemm pt-4 pb-4">
                <div class="col-md-12 mb-4">
                  <label>{{ $t("q_1") }}</label>
                  <Field
                    v-model="motivation_to_work"
                    name="motivation_to_work"
                    :placeholder="$t(`answer`)"
                    as="textarea"
                    class="p-3 mt-2"
                    style="height: 115px"
                  >
                  </Field>
                  <ErrorMessage
                    name="motivation_to_work"
                    class="commercial-form-error"
                  />
                </div>
                <div class="col-md-12 mb-4">
                  <label>{{ $t("q_2") }}</label>
                  <Field
                    v-model="previous_projects"
                    name="previous_projects"
                    :placeholder="$t(`answer`)"
                    as="textarea"
                    class="p-3 mt-2"
                    style="height: 115px"
                  >
                  </Field>
                  <ErrorMessage
                    name="previous_projects"
                    class="commercial-form-error"
                  />
                </div>
                <div class="col-md-12 mb-4">
                  <label>{{ $t("q_3") }}</label>
                  <Field
                    v-model="existing_franchises"
                    name="existing_franchises"
                    :placeholder="$t(`answer`)"
                    as="textarea"
                    class="p-3 mt-2"
                    style="height: 115px"
                  >
                  </Field>
                  <ErrorMessage
                    name="existing_franchises"
                    class="commercial-form-error"
                  />
                </div>
                <div class="col-md-12 mb-4">
                  <label>{{ $t("q_4") }}</label>
                  <Field
                    v-model="experience_in_food_beverage"
                    name="experience_in_food_beverage"
                    :placeholder="$t(`answer`)"
                    as="textarea"
                    class="p-3 mt-2"
                    style="height: 115px"
                  >
                  </Field>
                  <ErrorMessage
                    name="experience_in_food_beverage"
                    class="commercial-form-error"
                  />
                </div>
                <div class="col-md-12 mb-4">
                  <label>{{ $t("q_5") }}</label>
                  <Field
                    v-model="investment_amount"
                    :placeholder="$t(`answer`)"
                    name="investment_amount"
                    type="number"
                  />
                  <ErrorMessage
                    name="investment_amount"
                    class="commercial-form-error"
                  />
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="gray-title">
                <div class="row align-items-center">
                  <div class="col-sm-2 col-4">
                    <img src="@/assets/img/commercial-franchise/slider4.png" />
                  </div>
                  <div class="col-sm-10 col-8">
                    <h4>
                      {{
                        this.$i18n.locale == `ar`
                          ? `أتمم طلبك`
                          : `Complete your order`
                      }}
                    </h4>
                  </div>
                </div>
              </div>
              <div class="row pt-4 pb-4">
                <div class="col-md-12 mt-3">
                  <h5>{{ $t("Personal_data") }}</h5>
                  <div class="row">
                    <div class="col-4 key">{{ $t("FullName") }}</div>
                    <div class="col-4 value">{{ this.name }}</div>
                    <div class="col-4"></div>
                  </div>
                  <div class="row">
                    <div class="col-4 key">{{ $t("PhoneNumber") }}</div>
                    <div class="col-4 value">{{ this.phone }}</div>
                    <div class="col-4"></div>
                  </div>
                  <div class="row">
                    <div class="col-4 key">{{ $t("Email") }}</div>
                    <div class="col-4 value">{{ this.email }}</div>
                    <div class="col-4"></div>
                  </div>
                  <div class="row">
                    <div class="col-4 key">{{ $t("mcity") }}</div>
                    <div class="col-4 value">{{ this.city }}</div>
                    <div class="col-4"></div>
                  </div>
                </div>
                <div class="col-md-12 mt-3">
                  <label>{{ $t("q_1") }}</label>
                  <p>{{ this.motivation_to_work }}</p>
                </div>
                <div class="col-md-12 mt-3">
                  <label>{{ $t("q_2") }}</label>
                  <p>{{ this.previous_projects }}</p>
                </div>
                <div class="col-md-12 mt-3">
                  <label>{{ $t("q_3") }}</label>
                  <p>{{ this.existing_franchises }}</p>
                </div>
                <div class="col-md-12 mt-3">
                  <label>{{ $t("q_4") }}</label>
                  <p>{{ this.experience_in_food_beverage }}</p>
                </div>
                <div class="col-md-12 mt-3">
                  <label>{{ $t("q_5") }}</label>
                  <p>{{ this.investment_amount }}</p>
                </div>
              </div>
            </swiper-slide>
          </swiper>

          <div
            class="d-flex navigation-button mt-3 mb-4"
            :class="[
              index === 0
                ? ` justify-content-flex-end`
                : `justify-content-between`,
            ]"
          >
            <button
              class="next-step myPrev"
              :class="[index === 0 ? `d-none` : ``]"
            >
              {{ this.$i18n.locale == `ar` ? `الخطوة السابقة` : `Previous` }}
            </button>
            <template v-if="!$store.state.Token">
              <button
                @click="Checklogin()"
                v-if="!showLoginButton"
                class="next-step"
              >
                <span>
                  {{ $t("Next Step") }}
                </span>
              </button>
              <div v-else>
                <span class="alert alert-warning m-3">
                  {{
                    this.$i18n.locale == `ar`
                      ? `يجب القيام بتسجيل الدخول لاتمام الطلب`
                      : `You must be login for continues`
                  }}
                </span>
                <button @click="redirectToLogin" class="next-step">
                  {{ $t("Login") }}
                </button>
              </div>
            </template>
            <div v-else>
              <button type="submit" v-if="index != 3" class="next-step">
                <span>
                  {{ $t("Next Step") }}
                </span>
              </button>

              <button
                type="submit"
                id="but-submit"
                class="next-step myNext"
                v-else
              >
                <span>
                  {{ $t("Submit Order") }}
                </span>
              </button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import { defineAsyncComponent } from "vue";
import axios from "axios";
import { notify } from "@kyvg/vue3-notification";
import * as yup from "yup";
import { configure } from "vee-validate";
import intlTelInput from "../../intl-tel-input.js";
import Cookie from "cookie-universal";
const cookie = Cookie();
export default {
  data() {
    return {
      index: 0,
      swiper: null,
      commercialData: [],
      latitude: null,
      longitude: null,
      name: "",
      email: "",
      phone: null,
      city: "",
      educational_qualification: "",
      motivation_to_work: "",
      previous_projects: "",
      existing_franchises: "",
      experience_in_food_beverage: "",
      investment_amount: "",
      FullNumber: "",
      Status: false,
      showLoginButton: false,
    };
  },
  components: {
    Form,
    Field,
    ErrorMessage,
    GoogleMap: defineAsyncComponent(() =>
      import("@/components/CommercialFranchise/googleMap.vue")
    ),
  },
  setup() {
    configure({
      validateOnInput: true,
    });
    const schema = [
      yup.object({
        name: yup
          .string()
          .required(
            cookie.get("lang") == "ar" ? "حقل الاسم مطلوب" : "Name Required"
          ),
        email: yup
          .string()
          .required(
            cookie.get("lang") == "ar"
              ? "حقل البريد الالكتروني مطلوب"
              : "email required"
          )
          .email(
            cookie.get("lang") == "ar"
              ? " البريد الالكتروني غير مطابق"
              : "email dose not match"
          ),
        educational_qualification: yup
          .string()
          .required(
            cookie.get("lang") == "ar"
              ? "المؤهل العلمي مطلوب"
              : "Educational Qualification Required"
          ),
      }),
      yup.object({
        // latitude: yup.string().required(),
        // longitude: yup.string().required(),
        city: yup
          .string()
          .required(
            cookie.get("lang") == "ar"
              ? " اختيار المدينة مطلوب"
              : "City Required"
          ),
      }),
      yup.object({
        motivation_to_work: yup
          .string()
          .required(
            cookie.get("lang") == "ar"
              ? "دافع العمل مطلوب"
              : "motivation to work Required"
          ),
        previous_projects: yup
          .string()
          .required(
            cookie.get("lang") == "ar"
              ? "المشاريع السابقة مطلوب"
              : "previous projects Required"
          ),
        existing_franchises: yup
          .string()
          .required(
            cookie.get("lang") == "ar"
              ? "الامتيازات الموجودة مطلوب"
              : "existing franchises Required"
          ),
        experience_in_food_beverage: yup
          .string()
          .required(
            cookie.get("lang") == "ar"
              ? "خبرة في مجال الأغذية والمشروبات مطلوب"
              : "Experience in food beverage Required"
          ),
        investment_amount: yup
          .mixed()
          .test(
            "number",
            (field) => {
              const lang = cookie.get("lang");
              return lang === "ar" ? "يجب إدخال رقم" : "must enter a number";
            },
            (value) => typeof value === "number"
          )
          .required(
            cookie.get("lang") == "ar"
              ? "مبلغ الاستثمار"
              : "investment amount required"
          ),
      }),
    ];
    return {
      schema,
    };
  },
  computed: {
    currentSchema() {
      return this.schema[this.index];
    },
  },
  methods: {
    handleArrayFromChild(array) {
      console.log(array);

      this.latitude = array.latitude;
      this.longitude = array.longitude;
      this.city = array.city;
      // const inputLatitude = document.querySelector('#latitude');
      // const inputLongitude= document.querySelector('#longitude');
      // const inputCity = document.querySelector('#city');
      // inputLatitude.value = this.latitude;
      // inputLongitude.value = this.longitude;
      // inputCity.value = this.city;
    },
    onSwiper(swiper) {
      this.swiper = swiper;
    },
    onSlideChange() {
      this.index = this.swiper.activeIndex;
    },
    Checklogin() {
      notify({
        type: "warning",
        text:
          this.$i18n.locale == "ar"
            ? "يجب القيام بتسجيل الدخول لاتمام الطلب"
            : "You must be login for continues",
      });
      this.showLoginButton = true;
    },
    redirectToLogin() {
      this.$router.push("/login");
    },
    async onSubmit(values) {
      if (this.index == 0) {
        if (this.FullNumber) {
          this.index++;
          this.swiper.slideTo(this.index);
        }
      }
      if (this.index == 1) {
        console.log("lllll", this.city);

        if (this.city) {
          this.index++;
          this.swiper.slideTo(this.index);
        }
      }
      if (this.index == 2) {
        if (this.motivation_to_work != "") {
          this.index++;
          this.swiper.slideTo(this.index);
        }
      }
      if (this.index > 2) {
        const formData = {
          name: values.name,
          email: values.email,
          phone: this.FullNumber,
          latitude: this.latitude.toString(),
          longitude: this.longitude.toString(),
          educational_qualification: values.educational_qualification,
          motivation_to_work: values.motivation_to_work,
          previous_projects: values.previous_projects,
          existing_franchises: values.existing_franchises,
          experience_in_food_beverage: values.experience_in_food_beverage,
          investment_amount: values.investment_amount,
        };
        if (this.Status) {
          document.getElementById("but-submit").setAttribute("disabled", "");
          await axios
            .post(`user/franchise`, formData, {
              headers: {
                Authorization: `Bearer ${cookie.get("Token")}`,
              },
            })
            .then((res) => {
              if (res.status == 200) {
                notify({
                  type: "success",
                  title: " تسجيل طلبك ",
                  text: "تم تسجيل طلبك بنجاح",
                });
                this.$store.commit("SET_JoinUsData", res.data.data);
              }
              this.$router.push("/");
            })
            .catch(function (error) {
              notify({
                type: "error",
                title: "خـطـأ !!",
                text: " حدث خطأ أثناء تسجيل طلبك",
              });
            });
        }
        this.Status = true;
      }
    },
  },

  mounted() {
    const output = document.querySelector("#output");
    const inputElement = document.querySelector("#phone");

    const iti = intlTelInput(inputElement, {
      i18n: {
        searchPlaceholder: this.$i18n.locale == "ar" ? "بحث" : "Search",
      },
      initialCountry: "auto",
      nationalMode: true,
      geoIpLookup: (callback) => {
        fetch("https://ipapi.co/json")
          .then((res) => res.json())
          .then((data) => callback(data.country_code))
          .catch(() => callback("us"));
      },
      utilsScript:
        "https://cdn.jsdelivr.net/npm/intl-tel-input@23.7.3/build/js/utils.js",
    });

    const handleChange = () => {
      let text;
      if (this.$i18n.locale == "ar") {
        if (inputElement.value != "") {
          text = iti.isValidNumber()
            ? ""
            : "رقم غير صحيح - الرجاء اعد المحاولة";
          if (iti.isValidNumber()) {
            this.FullNumber = iti.getNumber();
          }
        } else {
          text = "الرجاء ادخال رقم صحيح";
        }
      } else {
        if (inputElement.value != "") {
          text = iti.isValidNumber() ? "" : "Invalid number - please try again";
          if (iti.isValidNumber()) {
            this.FullNumber = iti.getNumber();
          } else {
            this.FullNumber = false;
          }
        } else {
          text = "Please enter a valid number below";
        }
      }
      const textNode = document.createTextNode(text);
      output.innerHTML = "";
      output.appendChild(textNode);
    };

    // listen to "keyup", but also "change" to update when the user selects a country
    inputElement.addEventListener("change", handleChange);
    inputElement.addEventListener("keyup", handleChange);
  },
  watch: {
    index(newindex, oldindex) {
      if (newindex < 2) {
        this.Status = false;
      }
    },
  },
};
</script>
